<template>
  <div class="entry">
    <Spinner :is-loading="loading" />
    <div class="buttons buttons-align-right">
      <v-button class="flat" style="margin: 0 5px; height: 46px" @click="goBackToEntries(selectedFormat)">
        <v-svg>cancel</v-svg>
        {{ $t('entry.goBackToEntry') }}
      </v-button>
    </div>
    <h1>{{ $t('entry.InventoriesForAll') }}</h1>
    <div class="details">
      <div>
        <span class="label">{{ $t('date') }}:</span> {{ date.toFormat('dd/MM/yyyy') }}
      </div>
      <div>
        <span class="label">{{ $t('user') }}:</span> {{ userCode }}
      </div>
      <div>
        <span class="label">{{ $t('inventoryDate') }}: </span>
        <select @change="onInventoryDateChange($event)">
          <option value="" :selected="selectedInventory === ''" disabled>{{ $t('entry.selectInventoryDate') }}</option>
          <option v-for="(inventory, index) in selectedSiteInventoriesDates" :key="index" :value="index">
            {{ index }}
          </option>
        </select>
        <div>
          <span class="label">{{ $t('siteId') }}: </span>
          <multi-select
            style="width: 1050px"
            :options="selectedSiteInventoriesDates[selectedInventory]"
            :is-btn-disabled="!selectedInventory"
            @selectedItems="onSiteChange($event)"
          />
        </div>
      </div>
    </div>
    <div style="height: 80%">
      <ag-grid-vue
        class="ag-theme-alpine"
        style="width: 1050px; height: 400px"
        :column-defs="columnValidationDefs"
        :row-data="validationInventoryData"
        :row-selection="null"
        :get-row-class="getRowClass"
        :suppress-scroll-on-new-data="true"
        @cell-clicked="onSelectionChanged"
        @grid-ready="onGridValidationReady"
      />
      <v-button slot="footer" :disabled="validationInventoryData.length === 0" class="small green" @click="saveValidations(true)">
        {{ $t('confirm') }}
      </v-button>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapActions, mapState, mapGetters } from 'vuex'
  import { DateTime } from 'luxon'
  import Spinner from '@/components/base/Spinner'
  import MultiSelect from '@/components/base/MultiSelect'
  import 'ag-grid-community/dist/styles/ag-grid.css'
  import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
  import { AgGridVue } from 'ag-grid-vue'
  import NumericEditor from '../components/grid/numericEditor.vue'
  import CellRenderer from '../components/grid/cellRenderer.vue'
  import GridEntries from '@/components/GridEntries'
  import MoodRenderer from '../components/grid/moodRenderer.vue'
  import CheckBoxRenderVue from '../components/grid/checkBoxRender.vue'
  import CustomHeader from '../components/grid/customHeader.vue'

  window.isCellEditable = function isCellEditable(params) {
    return params
  }
  export default {
    name: 'Entry',
    components: {
      Spinner,
      'ag-grid-vue': AgGridVue,
      numericEditor: NumericEditor,
      cellRenderer: CellRenderer,
      moodRenderer: MoodRenderer,
      CheckBoxRenderVue: CheckBoxRenderVue,
      customHeader: CustomHeader,
      GridEntries,
      MultiSelect
    },
    data() {
      return {
        allData: [],
        gridApi: null,
        gridValidationApi: null,
        siteSelected: '',
        rowData: null,
        getRowClass: null,
        loading: false,
        date: DateTime.local(),
        entries: [],
        userCode: this.$userInfos.uid,
        selectedFormat: '',
        inventoriesList: [],
        today: new Date(),
        start: 0,
        dataByCell: [],
        columnValidationDefs: [
          { headerName: 'Rayon', field: 'CR' },
          { headerName: 'A déclencher', field: 'Inventaire', cellRenderer: 'CheckBoxRenderVue' },
          { headerName: 'Contre Inventaire', field: 'contre', cellRenderer: 'CheckBoxRenderVue' }
        ],
        validationInventoryData: [],
        selectedInventory: '',
        selectedSiteInventoriesDates: [],
        inventoryFormat: '0002-INVENTAIRE',
        rayonList: [10, 11, 12, 13, 21, 22, 43, 50, 51, 60]
      }
    },
    computed: {
      ...mapGetters('configuration', ['currentClosureDate', 'inventoriesDates', 'validationInventories']),
      ...mapState('configuration', ['validIndicators'])
    },
    mounted() {
      this.selectedFormat = this.$route.query.format
      this.selectedSiteInventoriesDates = this.groupElementsByDateAndSite(this.inventoriesDates)
    },
    async created() {
      this.selectedFormat = this.$route.query.format
      this.getRowClass = (params) => {
        if (params.node.rowIndex % 2 != 0) {
          return 'gray-effect-rows'
        }
      }
    },
    methods: {
      ...mapActions('entries', ['insertEntries', 'fetchEntriesByParams', 'fetchEntries', 'fetchEntriesByParamsForAll']),
      ...mapActions('configuration', ['fetchValidationInventoriesForMultipleSite', 'saveValidationInventories']),
      groupElementsByDateAndSite(data) {
        // Use an object to store the grouped data
        const groupedData = {}

        // Iterate over each element in the array
        data.forEach((element) => {
          // Extract relevant properties
          const { inventoryDate, site } = element

          // Use seconds as the key for the first level of grouping
          const dateInSeconds = this.formatDate(new Date(inventoryDate.seconds * 1000))

          // If the key doesn't exist in the groupedData, initialize it with an empty array
          if (!groupedData[dateInSeconds]) {
            groupedData[dateInSeconds] = []
          }

          // Find the site within the dateInSeconds
          const existingSite = groupedData[dateInSeconds].includes(site)

          // If the site group doesn't exist, initialize it with an empty array
          if (!existingSite) {
            groupedData[dateInSeconds].push(site)
            groupedData[dateInSeconds].sort((a, b) => {
              return a - b
            })
          }
        })
        return this.orderArrayByDateKey(groupedData)
      },
      orderArrayByDateKey(obj) {
        const sortedKeys = Object.keys(obj).sort((a, b) => {
          const dateA = new Date(a.split('/').reverse().join('/'))
          const dateB = new Date(b.split('/').reverse().join('/'))
          return dateB - dateA
        })

        const sortedObject = {}
        sortedKeys.forEach((key) => {
          sortedObject[key] = obj[key]
        })

        return sortedObject
      },
      async onGridValidationReady(params) {
        this.gridApi = params.api
        await params.api.setRowData(this.validationInventoryData)
        this.gridValidationApi = params.api
      },
      onSelectionChanged(event) {
        const selectedCell = event
        const selectedCheckbox = selectedCell.event.target.checked
        const cellIndex = selectedCell.rowIndex
        if (selectedCheckbox !== undefined && selectedCell.colDef.field === 'Inventaire') {
          this.validationInventoryData[cellIndex].Inventaire = selectedCheckbox
        } else if (selectedCheckbox !== undefined && selectedCell.colDef.field === 'contre') {
          if (!this.validationInventoryData[cellIndex].Inventaire) {
            selectedCell.event.target.checked = false
            Vue.toasted.error(`Vous ne pouvez pas cocher cette case`)
          } else {
            this.validationInventoryData[cellIndex].contre = selectedCheckbox
          }
        }
      },

      generateRows(selectedSiteId) {
        let dataRows = []
        this.validationInventoryData = []

        const marketList = this.inventoriesList.map((inventory) => inventory.market)

        for (const CR of this.validResultCenterIds(selectedSiteId)) {
          if (this.rayonList.includes(parseInt(CR))) {
            // Conserver uniquement les rayons 10 11 12 13 21 22 43 50 51 60
            let concatRow = {}
            let mappedCR = { CR: CR + '- ' + this.getResultCenterLabel(CR) }

            concatRow = { ...concatRow, CR1: !this.selectedInventory ? null : marketList.includes(CR) ? 'Oui' : 'Non' }
            if (this.selectedInventory) {
              let validationData = {
                ...mappedCR,
                Rayon: CR,
                Inventaire: false,
                contre: false
              }
              this.validationInventoryData.push(validationData)
            }
          }
        }

        return dataRows
      },

      async onSiteChange(event) {
        this.siteSelected = event

        await this.fetchValidationInventoriesForMultipleSite({ site: this.siteSelected, inventoryDay: this.selectedInventory })
        this.reloadDataGrid(this.selectedInventory)

        if (event.length === 0) {
          this.gridApi.setRowData()
          this.gridApi.redrawRows()
        }
      },

      async onInventoryDateChange(event) {
        this.loading = true
        this.selectedInventory = event.target.value

        this.loading = false
      },

      async reloadDataGrid(selectedInventory = null) {
        if (this.siteSelected.length) {
          this.allData = await this.fetchEntriesByParamsForAll({
            site: this.siteSelected,
            format: this.selectedFormat.split(),
            imputationDate: selectedInventory
          })
          this.gridApi.setRowData(this.generateRows(this.siteSelected, this.inventoryFormat))
          this.gridApi.redrawRows()
        }
      },
      async saveValidations(forAllSite = false) {
        this.loading = true
        await this.saveValidationInventories({
          data: this.validationInventoryData,
          selectedInventory: this.selectedInventory,
          siteSelected: this.siteSelected,
          user: this.$userInfos.uid,
          forAllSite
        })
        this.gridValidationApi.setRowData(this.validationInventories)
        this.loading = false
        await this.reloadDataGrid(this.selectedInventory)
      },
      validResultCenterIds() {
        return [...new Set(this.validIndicators[this.selectedFormat].CRs.map((CRS) => CRS.CR.toString()))]
          .map((uc) => (uc.length === 4 ? '0' + uc : uc))
          .sort()
      },

      getResultCenterLabel(resultCenterID) {
        return resultCenterID && this.validResultCenterIds().includes(resultCenterID)
          ? this.validIndicators[this.selectedFormat].CRs.find((CR) => CR.CR.toString() === resultCenterID).LibelleCR
          : ''
      },
      padTo2Digits(num) {
        return num.toString().padStart(2, '0')
      },

      formatDate(date) {
        return [this.padTo2Digits(date.getDate()), this.padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .label-mr {
    margin-right: 4px;
  }

  .buttons {
    position: absolute;
    display: flex;
    margin-bottom: 4rem;

    &-align-right {
      flex-direction: row;
      top: 6.4rem;
      right: 6.4rem;
    }
  }

  .entry {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    overflow-y: scroll;
  }

  .details {
    div {
      margin-top: 0.6rem;
      margin-bottom: 0.6rem;
    }

    .label {
      @extend %font-bold;
    }
  }

  .small.green {
    background: #58e8ab !important;
  }
</style>
